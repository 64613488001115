export const NEXT_STEP = 'NEXT_STEP';
export const GO_TO_STEP = 'GO_TO_STEP';
export const PREVIOUS_STEP = 'PREVIOUS_STEP';
export const UPDATE_FORM = 'UPDATE_FORM';
export const LOAD_STATE_FROM_URL = 'LOAD_STATE_FROM_URL';

export const previousStep = () => ({
  type: PREVIOUS_STEP,
});

export const nextStep = () => ({
  type: NEXT_STEP,
});

export const goToStep = (step: number) => ({
  type: GO_TO_STEP,
  payload: step,
});

export const updateForm = (data: unknown) => ({
  type: UPDATE_FORM,
  payload: data,
});

export const loadStateFromURL = (data: unknown) => ({
  type: LOAD_STATE_FROM_URL,
  payload: data,
});
