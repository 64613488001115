import { IconType } from '@react-icons/all-files/lib';

export class StepsOverviewItem {
  public constructor(
    public step: number,
    public icon: IconType,
    public title: string,
    public visited: boolean,
  ) {}
}
