import React from 'react';
import { Provider } from 'react-redux';

import { GatsbyBrowser } from 'gatsby';

import createStore from './src/state/create-store';

export const wrapWithProvider: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return <Provider store={store}>{element}</Provider>;
};
