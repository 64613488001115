import { RoofType } from './roof-type.enum';

export class Carport {
  public constructor(
    public length: number,
    public width: number,
    public height: number,
    public roofType: RoofType,
    public walls: 'yes' | 'no',
    public leftWall: boolean,
    public backWall: boolean,
    public rightWall: boolean,
    public amountOfCollaboration: number,
  ) {}
}
